import React from 'react'
import styled from 'styled-components'
import { createApiHook, createFetcher } from '@foundation-lib/api'
import Form from '@foundation-lib/context-form/dist/Form'
import StandardLayout from '../components/layouts/StandardLayout'
import TextField from '../components/form/context-form-inputs/Text'
import SubmitButton from '../components/form/SubmitButton'
import BodyButton from '../components/BodyButton'

const useApi = createApiHook({ fetcher: createFetcher() })

const MyTextField = styled(TextField).attrs({ margin: 'normal' })`
  div {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
    max-width: 100%;
  }
  clear: both;
  display: block;
`

export default () => {
  const {
    status: { isLoading, hasError },
    body,
    send,
    reset,
  } = useApi({
    url: 'https://usebasin.com/f/7888b38d9a6f.json',
    method: 'post',
    lazy: true,
    config: {
      withCredentials: false,
    },
  })

  return (
    <StandardLayout title="Contact Us">
      <h2>Call us</h2>
      <p>
        If you would like to make a purchase over the phone or would like
        pricing on bulk purchases (24 copies or more of one of our books),
        please call us at <b>(740) 831-4249</b>.
      </p>
      <h2>E-mail us</h2>
      {body?.success ? (
        <>
          <p>Your submission was successful! We will be in touch soon.</p>
          <p>
            <BodyButton
              onClick={e => {
                e.preventDefault()
                reset()
              }}
            >
              Click here to send another message
            </BodyButton>
          </p>
        </>
      ) : (
        <Form
          onSubmit={data => {
            console.log('sending', data)
            send(data)
          }}
          isSubmitting={isLoading}
        >
          <MyTextField label="Name" name="name" />
          <MyTextField label="E-mail address" name="email" />
          <MyTextField label="Subject" name="subject" fullWidth />
          <MyTextField
            label="Message"
            name="message"
            multiline
            rows={3}
            fullWidth
          />
          {hasError && (
            <div style={{ color: 'red', fontWeight: 'bold' }}>
              <br />
              There was an error with your submission. Please try again later.
            </div>
          )}
          <br />
          <br />
          <SubmitButton isSubmitting={isLoading}>Send</SubmitButton>
        </Form>
      )}
    </StandardLayout>
  )
}
