"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _fp = require("lodash/fp");

var _react = require("react");

var _lodash = require("lodash");

var applyReducers = function applyReducers(data) {
  var reducers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return reducers.reduce(function (accData, reducer) {
    var result = reducer(accData);
    return result || accData;
  }, data);
};

var useDeepState = function useDeepState(_initialData) {
  var reducers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var initialData = _initialData ? applyReducers((0, _lodash.cloneDeep)(_initialData)) : {};

  var _useState = (0, _react.useState)(initialData),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      data = _useState2[0],
      setData = _useState2[1];

  var setAtPath = function setAtPath(path, val) {
    return setData(applyReducers((0, _fp.set)(path, val, data), reducers));
  };

  var reset = function reset() {
    return setData(initialData);
  };

  return [data, setAtPath, reset];
};

var _default = useDeepState;
exports["default"] = _default;