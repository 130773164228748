"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _useValidator = _interopRequireDefault(require("./useValidator"));

var _useFieldValue = _interopRequireDefault(require("./useFieldValue"));

var _useHelpers2 = _interopRequireDefault(require("./useHelpers"));

var _useIsSubmitting = _interopRequireDefault(require("./useIsSubmitting"));

var getValueFromEvent = function getValueFromEvent(e) {
  if (e.target.type === 'checkbox') return e.target.checked;
  return e.target.value;
};

var useField = function useField(_ref) {
  var name = _ref.name,
      required = _ref.required,
      customValidation = _ref.customValidation;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      touched = _useState2[0],
      setTouched = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      focused = _useState4[0],
      setFocused = _useState4[1];

  var value = (0, _useFieldValue["default"])(name);
  var isSubmitting = (0, _useIsSubmitting["default"])();

  var _useHelpers = (0, _useHelpers2["default"])(),
      setFieldValue = _useHelpers.setFieldValue;

  var isValid = (0, _useValidator["default"])(name, value, {
    required: required,
    customValidation: customValidation,
    onFormValidate: function onFormValidate() {
      return setTouched(true);
    }
  });

  var onChange = function onChange(e) {
    setFieldValue(name, getValueFromEvent(e));
  };

  var onFocus = function onFocus() {
    setFocused(true);
  };

  var onBlur = function onBlur() {
    setFocused(false);
    setTouched(true);
  };

  var hasError = touched && !isValid;
  return {
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: onChange,
    hasError: hasError,
    focused: focused,
    value: value,
    disabled: isSubmitting
  };
};

var _default = useField;
exports["default"] = _default;