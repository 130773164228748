import React from 'react'
import { TextField } from '@material-ui/core'
import useField from '@foundation-lib/context-form/dist/hooks/useField'

export default ({ name, required, customValidation, ...otherProps }) => {
  const {
    hasError,
    focused,
    value = '',
    disabled,
    ...otherFieldProps
  } = useField({
    name,
    required,
    customValidation,
  })

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      error={hasError}
      required={required}
      value={value}
      name={name}
      disabled={disabled}
      {...otherFieldProps}
      {...otherProps}
    />
  )
}
