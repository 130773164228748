"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _HelpersContext = _interopRequireDefault(require("../contexts/HelpersContext"));

var _default = function _default() {
  return (0, _react.useContext)(_HelpersContext["default"]) || {};
};

exports["default"] = _default;