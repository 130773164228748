"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var _useData = _interopRequireDefault(require("./useData"));

var _default = function _default(path) {
  return (0, _lodash.get)((0, _useData["default"])(), path);
};

exports["default"] = _default;