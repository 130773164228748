import React from 'react'
import styled from 'styled-components'
import SEO from '../Seo'
import BaseLayout from './BaseLayout'

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;
  @media only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 15px;
  }
`

export default ({ children, title, seoTitle = title }) => {
  return (
    <BaseLayout>
      <Container>
        <SEO title={seoTitle} />
        <h1>{title}</h1>

        {children}

        <footer />
      </Container>
    </BaseLayout>
  )
}
