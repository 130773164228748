"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = require("react");

var _default = function _default() {
  var list = (0, _react.useRef)([]);

  var add = function add(item) {
    list.current = [].concat((0, _toConsumableArray2["default"])(list.current), [item]);
    return function () {
      list.current = list.current.filter(function (currentItem) {
        return currentItem !== item;
      });
    };
  };

  return [list.current, add];
};

exports["default"] = _default;