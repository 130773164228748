import React from 'react'
import { CircularProgress } from '@material-ui/core'
import BodyButton from '../BodyButton'

export default ({ isSubmitting, children }) => (
  <BodyButton type="submit" disabled={isSubmitting}>
    {isSubmitting ? (
      <>
        <CircularProgress size={15} />
        &nbsp;&nbsp;
      </>
    ) : (
      ''
    )}
    {children}
  </BodyButton>
)
