import styled from 'styled-components'
import Button from './Button'

export default styled(Button)`
  ${p =>
    ({
      medium: `
      padding: 0.56rem 1.12rem;
      font-size: 21px;
    `,
      large: `
      padding: 0.75rem 1.5rem;
      font-size: 28px;
    `,
    }[p.size || 'medium'])}
  background-color: #daa900;
  color: #ffffff;
  border: none;
  &:hover {
    background-color: #c69a01;
  }
  &:focus {
    box-shadow: 0 0 0 0.1875rem rgba(218, 169, 0, 0.35);
  }
  &:active {
    background-color: #c69a01;
    &:focus {
      box-shadow: 0 0 0 0.1875rem rgba(218, 169, 0, 0.35);
    }
  }
  ${p =>
    p.disabled &&
    `
      opacity: 60%;
      cursor: not-allowed;
      &:hover {
        background-color: #daa900;
      }
    `}
`
