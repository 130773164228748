"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var _react = require("react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = function _default() {
  var validators = (0, _react.useRef)({});

  var addValidator = function addValidator(name, fn) {
    validators.current = _objectSpread(_objectSpread({}, validators.current), {}, (0, _defineProperty2["default"])({}, name, fn));
    return function () {
      validators.current = (0, _lodash.omit)(validators.current, name);
    };
  };

  var validate = function validate() {
    var validationResults = (0, _lodash.mapValues)(validators.current, function (listener) {
      return listener && listener();
    });
    return (0, _lodash.every)(Object.values(validationResults));
  };

  return {
    addValidator: addValidator,
    validate: validate
  };
};

exports["default"] = _default;