"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _IsSubmittingContext = _interopRequireDefault(require("../contexts/IsSubmittingContext"));

var _default = function _default() {
  return (0, _react.useContext)(_IsSubmittingContext["default"]) || false;
};

exports["default"] = _default;