"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _AddValidatorContext = _interopRequireDefault(require("../contexts/AddValidatorContext"));

var useValidator = function useValidator(name, value, _ref) {
  var required = _ref.required,
      customValidation = _ref.customValidation,
      _ref$onFormValidate = _ref.onFormValidate,
      onFormValidate = _ref$onFormValidate === void 0 ? function () {} : _ref$onFormValidate;

  var addValidator = (0, _react.useContext)(_AddValidatorContext["default"]) || function () {
    return function () {};
  };

  var isValid = (0, _react.useMemo)(function () {
    if (required && !value) return false;
    if (customValidation && !customValidation(value)) return false;
    return true;
  }, [required, value]);
  (0, _react.useEffect)(function () {
    return addValidator(name, function () {
      onFormValidate();
      return isValid;
    });
  }, [isValid]);
  return isValid;
};

var _default = useValidator;
exports["default"] = _default;